<template>
  <div class="container h-100">
    <!-- <div>all</div> -->
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-lg-6">
        <div class="card card-custom">
          <div class="py-5 text-center">
            <Logo></Logo>
            <h6 class="card-subtitle text-muted">Prepaid Fiber</h6>
          </div>

          <!-- <form onS></form> -->
          <div class="card-body">
            <div class="row">
              <div class="col-12"></div>
            </div>

            <div class="row my-5">
              <div class="col-md-6 offset-md-3">
                <button
                  class="btn btn-lg btn-jungle-green w-100"
                  @click.prevent="goBack"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";
// import axios from "axios";
export default {
  components: {
    Logo,
  },

  data() {
    return {};
  },

  methods: {
    goBack() {
      this.$router.push({ path: "/" });
    },
  },

  created() {
    const appData = this.$store.getters.GET_APPLICATIONFORMDATA;
    const isEmpty = Object.keys(appData).length === 0;
    if (isEmpty) {
      this.$router.push({ path: "/" });
      return;
    }

    this.$store.dispatch("ADD_AFFILIATECODE", "");
    this.$swal("Failed", "Payment failed, Please try again", "error").then(
      () => {
        this.goBack();
      }
    );
  },

  computed: {
    getUser() {
      return this.$store.getters.GET_USER;
    },
  },
};
</script>
