<template>
  <div>
    <img
      src="@/assets/img/s2s-new-logo.png"
      height="100"
      class="my-2"
      alt="logo"
    />
  </div>
</template>

<script>
export default {
  name: "Logo",
};
</script>
